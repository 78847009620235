// Initialize first
@import '../init';

// Style Here
.contact {
  // Section Hero
  .hero {
    .container {
      padding-top: toRem(166);
      padding-bottom: toRem(80);
      display: flex;
      flex-direction: column;
      gap: toRem(64);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
        padding-top: toRem(136);
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(48);
      }
    }

    &__header {
      max-width: 460px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: toRem(24);

      h1 {
        color: $color-white;
      }

      p {
        color: $color-lighter-grey;
      }
    }

    &__form {
      width: calc(912 / 1100 * 100%);
      display: flex;
      flex-direction: column;
      gap: toRem(48);
      padding: toRem(48) toRem(64);
      background: $color-white;
      border-radius: 10px;
      box-shadow: $shadow-lg;

      @include media-breakpoint-down(sm) {
        padding: toRem(28) toRem(16);
        width: 100%;
      }

      .form-wrap {
        display: flex;
        flex-direction: column;
        gap: toRem(32);

        .form-line {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: toRem(32);
          align-items: center;

          @include media-breakpoint-down(lg) {
            flex-direction: column;
          }

          .form-group {
            width: 100%;
          }
        }
      }

      .button-wrap {
        max-width: 176px;

        @include media-breakpoint-down(lg) {
          max-width: 100%;
        }
      }
    }
  }

  //   Section More Help
  .more-help {
    position: relative;
    // Pattern
    .pattern {
      position: absolute;
      width: 1714.5px;
      height: 560px;
      top: toRem(-5);
      left: toRem(-56);
      background-image: url('../../media/images/patterns-and-ornaments/contact-v1-pattern.svg');
      background-position: center;
      background-size: contain;
      z-index: -1;
    }

    // Content
    .container {
      padding: toRem(80) 0;
      display: flex;
      flex-direction: column;
      gap: toRem(64);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(48);
      }
    }

    .more__header {
      color: $color-dark-deep-blue;
      text-align: center;
    }

    .more__cards {
      --bs-gutter-x: 24px;
      --bs-gutter-y: 0;

      @include media-breakpoint-down(lg) {
        --bs-gutter-x: 0;
        --bs-gutter-y: 24px;
      }

      .card-more {
        display: flex;
        flex-direction: column;
        gap: toRem(32);
        padding: toRem(32);
        background-color: $color-white;
        border-radius: 10px;
        box-shadow: $shadow-sm;

        .content-wrap {
          display: flex;
          flex-direction: column;
          gap: toRem(24);

          .text-content {
            display: flex;
            flex-direction: column;
            gap: toRem(12);

            h4 {
              color: $color-dark-blue;
            }
          }
        }
      }
    }
  }
}
